* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.body {
    font-family: "Open Sans", sans-serif;
}

.title {
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
}

.questions-container {
    max-width: 800px;
    margin: 0 auto;
}

.question {
    border-bottom: 1px solid #fff;
}
.question button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 15px;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background-color: rgb(154 196 184 / 15%);
    margin-bottom: 5px;
}
.question p {
    font-size: 17 px;
    max-height: 0;
    opacity: 0;
    line-height: 1.5;
    overflow: hidden;
    transition: all 0.6s ease;
}
.d-arrow {
    transition: transform 0.5s ease-in;
    color: #fff;
}

/*add this class when click*/
.question p.show {
    max-height: 200px;
    opacity: 1;
    padding: 0px 15px 30px 15px;
}
.question button .d-arrow.rotate {
    transform: rotate(180deg);
}

.fa-chevron-down:before {
    color: black;
}
