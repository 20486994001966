.react-tagsinput {
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 5px;
    overflow: hidden;
    padding-left: 5px;
    margin-bottom: 5px;
}

.react-tagsinput--focused {
    border-color: #c0ccda;
}

.react-tagsinput-tag {
    margin-top: 5px;
    background-color: white;
    border-radius: 2px;
    border: 1px solid #c0ccda;
    color: #638421;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-right: 5px;
    padding: 5px;
}

.dpdown-transactions-search-filter > button {
    border-radius: 0;
    border: none;
    background-color: white;
    border-top: 1px solid #485e9029;
    border-bottom: 1px solid #485e9029;
    height: 40px;
}

.dpdown-transactions-search-filter > button > i {
    color: #485e90;
}

.dpdown-transactions-search-filter > button:hover > i {
    color: white;
}

.dpdown-transactions-search-filter > button:focused > i {
    color: white;
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: " ×";
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 100%;
}

.select-search-input {
    border-radius: 5px;
    border: 1px solid #c0ccda;
    background-color: white;
    font-size: 14px;
    width: 100%;
}

.select-search-option {
    width: 100%;
    background-color: white;
    border: 1px solid #ededed;
    font-size: 13px;
    color: #000;
}

.select-search-option:hover {
    background-color: white;
    color: #000;
}

/* ------------ */

.select-search-container {
    --select-search-background: rgb(197, 219, 253);
    --select-search-border: #dce0e8;
    --select-search-selected: #485e9029;
    --select-search-text: rgb(80, 80, 80);
    --select-search-subtle-text: #6c6f85;
    --select-search-inverted-text: var(--select-search-background);
    --select-search-highlight: #b8d6ff;
    --select-search-font: "Inter", sans-serif;
    font-family: var(--select-search-font);
    color: var(--select-search-text);
    box-sizing: border-box;
    width: 100%;
}

.select-search-select {
    width: 100%;
    border-radius: 4px;
    border: none;
}

.select-search-not-found {
    margin-top: -5px;
    height: 26px;
    font-size: 17px;
    cursor: default;
}

.PhoneInputInput {
    border: 1px solid #c0ccda;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    width: 100%;
}

.required:after {
    content: "*";
    color: red;
    margin-left: 3px;
    position: absolute;
}

.TabList-class {
    display: flex;
    flex-wrap: wrap;
    border-color: #485e9029;
    padding: 0 25px;
    border-bottom-width: 1px;
    background-color: white;
    border-bottom: 2px solid #485e9029;
}
@media (max-width: 1440px) {
    .TabList-class {
        padding-inline: 1rem;
        overflow-x: scroll;
        overflow-y: hidden;
        flex-wrap: nowrap;
    }
    .TabList-class li {
        white-space: nowrap;
    }
}
